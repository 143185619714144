.Toastify__toast {
  border-radius: 0.25em;
}

.Toastify__toast-body {
  padding: 0.25em;
  margin: 0;
  display: flex;
  align-items: center;
}

.Toastify__toast_variant_outline {
  background-color: white;
  color: var(--chakra-colors-mandala-black-900);

  border-left: 5px solid;
}

.Toastify__toast_variant_outline.Toastify__toast--info {
  border-color: var(--chakra-colors-mandala-blue-500);
}

.Toastify__toast_variant_outline.Toastify__toast--success {
  border-color: var(--chakra-colors-mandala-green-500);
}

.Toastify__toast_variant_outline.Toastify__toast--warning {
  border-color: var(--chakra-colors-mandala-yellow-500);
}

.Toastify__toast_variant_outline.Toastify__toast--error {
  border-color: var(--chakra-colors-mandala-red-500);
}

.Toastify__toast_variant_solid {
  color: #fff;
}

.Toastify__toast_variant_solid.Toastify__toast--info {
  background-color: var(--chakra-colors-mandala-blue-500);
}

.Toastify__toast_variant_solid.Toastify__toast--success {
  background-color: var(--chakra-colors-mandala-green-500);
}

.Toastify__toast_variant_solid.Toastify__toast--warning {
  background-color: var(--chakra-colors-mandala-yellow-500);
}

.Toastify__toast_variant_solid.Toastify__toast--error {
  background-color: var(--chakra-colors-mandala-red-500);
}
