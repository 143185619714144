html {
  font-family: "IBM Plex Sans", sans-serif;
  scrollbar-width: none;
  height: 100%;
  width: 100%;
}

html::-webkit-scrollbar {
  width: 0;
}

/* Make the App both width and height 100% */
body {
  height: inherit;
  width: inherit;
}

#__next {
  height: inherit;
  width: inherit;
}

.element1 {
  top: 16px;
  left: 50px;
  height: 24px;
  width: 220px;
}

.text-lg {
  font-size: 28px;
}

.text-md {
  font-size: 20px;
}

.text-sm {
  font-size: 14px;
}

.text-xs {
  font-size: 12px;
}

.react-colorful__saturation {
  border-radius: 0.25em 0.25em 0 0 !important;
}

.react-colorful__hue {
  height: 40px;
  margin-top: 0.5em;
  border-radius: 0.25em !important;
}

.react-colorful__last-control {
  height: 0.75em !important;
  margin: 1em 0.5em;
  border-radius: 1.5em !important;
}

.react-colorful__hue-pointer {
  width: 1.5em !important;
  height: 1.5em !important;
  border-radius: 0;
}
