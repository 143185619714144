.af {
  font-family: "Cabin", sans-serif;
}

.rf {
  font-family: "IBM Plex Sans", sans-serif;
}

.df {
  font-family: "IBM Plex Mono", monospace;
}

.pc {
  color: "#3f3c56";
}

.disabled {
  cursor: not-allowed;
}

.b--db {
  border-color: #0086ff;
}

.bg-db {
  background-color: #164985;
}

.bg-dy {
  background-color: #ffc200;
}

.bg-dr {
  background-color: #d54200;
}

.bg-dg {
  background-color: #008f58;
}
