/**
CSS Shorthand
**/

.sticky {
  position: sticky;
}

/**
SVG Styling
**/
.cls-1,
.cls-4 {
  fill: none;
}
.cls-2 {
  opacity: 0.6;
}
.cls-3 {
  stroke: #000;
  stroke-width: 0.77px;
  fill: url(#New_Pattern);
}
.cls-3,
.cls-4 {
  stroke-linecap: round;
  stroke-miterlimit: 10;
}
.cls-4 {
  stroke: #3d3d3d;
  stroke-width: 1.08px;
}

.cls-5 {
  stroke: #000;
  stroke-miterlimit: 5;
  stroke-width: 0.5px;
  fill: url(#Kotak);
}

/**
Accordion
**/

/* Style the accordion chevron icon */
.accordion-icon {
  transition: transform 0.2s ease;
}

/* Style the accordion content panel. Note: hidden by default */
.accordion-content {
  background-color: white;
  overflow: hidden;
  transition: max-height 0.4s ease;
}

/**
Overlay Text
**/

.hide-overlay .overlay {
  opacity: 0;
  transition: opacity 0.15s ease-in;
}
.hide-overlay:hover .overlay,
.hide-overlay:focus .overlay,
.hide-overlay:active .overlay {
  opacity: 0.9;
  transition: opacity 0.15s ease-in;
}

/**
 * Delman Styling
 **/

.delman-text-green {
  color: #2a7f2e;
}

.delman-text-blue {
  color: #164985;
}

.delman-bg-near-green {
  background-color: #f0fff0;
  border-color: #dcffdc;
}

.delman-bg-near-blue {
  background-color: #f0f7fe;
  border-color: #e1effc;
}

/* Dashboard Full Screen */
.fullscreen-enabled {
  background: #f4f4f4;
}

.js-sidebar-item {
  border-left: 4px solid transparent;
}

.js-sidebar-item--active {
  font-weight: bold;
}

.js-sidebar-item--active,
.js-sidebar-item:hover {
  background-color: #ebedf0;
  border-left-color: #1b5ba5;
  color: #1b5ba5;
}

/* 
  React Ace Highlighter
*/
.ace_keyword {
  color: #2f83e1 !important;
}

.ace_string {
  color: #3da942 !important;
}

.ace_editor {
  background-color: transparent;
}
